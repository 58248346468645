import { Vue, Component, Emit } from "vue-property-decorator";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltAutoButton from "@/core/components/alt-auto-button";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { ClientsImportContextOut, ClientsImportModal } from "../clients-import.modal";
import { ClientsExportContextOut, ClientsExportModal } from "../clients-export.modal";
import { Formatter } from "@/utils/formatter";

@Component({
    name: "clients-toolbar",
    components: { AltAutoButton, ModalComponent },
})
export default class ClientsToolbar extends Vue {
    @Emit("create")
    private startCreate(): void {
        return;
    }

    private clientsImportModal: ClientsImportModal;
    private clientsExportModal: ClientsExportModal;

    public constructor() {
        super();

        this.clientsImportModal = new ClientsImportModal();
        this.clientsImportModal.onImport = this.import.bind(this);

        this.clientsExportModal = new ClientsExportModal();
        this.clientsExportModal.onExport = this.export.bind(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Clients, PermissionRight.Create);
            },
        };
    }

    private async showModalImport(): Promise<void> {
        await this.clientsImportModal.show();
    }

    private async showModalExport(): Promise<void> {
        await this.clientsExportModal.show();
    }

    private async import(context: ClientsImportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            if (!context.file) {
                this.$alt.toast.warning("Файл не выбран.");
                return false;
            }

            const company = await this.$info.getCompany();
            const tag = `import_${Formatter.now({ format: "YYYYMMDD_HHmmss" })}`;
            const dto = { tags: [tag] };
            const service = this.$alt.system.usecase.createClientUseCase();
            await service.import(company.id, context.file, dto);
            this.$alt.toast.success("Данные успешно загружены.");
            this.$emit("refresh");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async export(context: ClientsExportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            const company = await this.$info.getCompany();
            const dto = { ...context.filters };
            const service = this.$alt.system.usecase.createClientUseCase();
            const file = await service.export(company.id, dto);

            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([file]));
            link.download = `export_${Formatter.now({ format: "YYYYMMDD_HHmmss" })}.csv`;
            link.click();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);

            this.$alt.toast.success("Файл отправлен на скачивание.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
