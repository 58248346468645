import { Modal } from "@core/components/alt-ui/modal";
import { Button, Badge, Control, Html, Icon, List, Panel, Label } from "@core/components/alt-ui/controls";
import router from "@/router/router";
import { IOrder, IOrderStage, OrderStageType } from "@lib";
import * as filters from "@/filters";
import { getOrderStageColor, getOrderStageText } from "../orders/orders-defaults";

export interface IClientOrdersModalContext {
    orders: IOrder[];
    orderStages: IOrderStage[];
    canOrdersRead: boolean;
}

export class ClientOrdersModal extends Modal<IClientOrdersModalContext, void> {
    private listOrders!: List<IOrder>;
    private lbNotFound!: Label;

    private btnClose!: Button;
    private pnlFooter!: Panel;

    private context: IClientOrdersModalContext | null = null;

    public constructor() {
        super("client-orders-modal", "Заявки клиента");
        this.initializeControls();
    }

    public show(context: IClientOrdersModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        return super.show();
    }

    private getControlId(controlId: string): string {
        return `client-orders-modal.${controlId}`;
    }

    protected initializeControls(): void {
        this.listOrders = new List<IOrder>();
        this.listOrders.id = this.getControlId("orders-list");
        this.listOrders.class = "mt-1";
        this.listOrders.items = this.context?.orders ?? [];
        this.listOrders.header = item => `Заявка #${item.number}`;
        this.listOrders.content = item => {
            if (!item.products || item.products.length === 0) {
                return "";
            }

            return item.products[0].info.name;
        };
        this.listOrders.right = (item, index) => {
            // TODO: Так как не работают классы с margin в контролах, вложенных в Panel
            const spacer = new Html();
            spacer.id = this.getControlId(`orders-list.item-spacer-${index}`);
            spacer.html = `<div style="width: 10px"></div>`;

            const btnLink = new Button();
            btnLink.id = this.getControlId(`orders-list.item-open-order-${index}`);
            btnLink.help = "Перейти к заявке";
            btnLink.variant = "flat-primary";
            btnLink.icon = new Icon();
            btnLink.icon.icon = "ExternalLinkIcon";
            btnLink.addClickHandler((sender, e) => this.openOrder(sender, { ...e, item }));

            const pnlRight = new Panel();
            pnlRight.id = this.getControlId(`orders-list.item-panel-${index}`);
            pnlRight.class = "flex items-center";

            const stage = this.context?.orderStages.find(stage => stage.id === item.stage);

            if (stage) {
                const bdgStage = new Badge();
                bdgStage.id = this.getControlId(`orders-list.item-stage-${index}`);
                bdgStage.text = stage.name;
                bdgStage.style = `background-color: ${stage.color}`;

                pnlRight.addControl(bdgStage);
            }

            if (this.context?.canOrdersRead) {
                pnlRight.addControls([spacer, btnLink]);
            }

            return pnlRight;
        };
        this.listOrders.footer = item => {
            return filters.datetime.datetime(item.createdAt, "L LT");
        };

        this.lbNotFound = new Label();
        this.lbNotFound.id = this.getControlId("not-found");
        this.lbNotFound.text = "Заявки не найдены";
        this.lbNotFound.class = "mt-1";

        this.btnClose = new Button();
        this.btnClose.id = this.getControlId("close");
        this.btnClose.text = "Закрыть";
        this.btnClose.variant = "outline-danger";
        this.btnClose.addClickHandler(() => this.hide());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnClose);
    }

    public get controls(): Control[] {
        return [this.context?.orders.length === 0 ? this.lbNotFound : this.listOrders, this.pnlFooter];
    }

    public openOrder(sender: any, e: any): void {
        const routeData = router.resolve({ name: "orders", query: { id: e.item.id } });
        window.open(routeData.href, "_blank");
    }
}
